'use strict'

const platformUtils = require('santa-platform-utils/dist/esm/viewer-platform-worker-api')

module.exports = {
  getApi(routersMap, appStorage, pubSubService) {
    return {
      links: {
        toUrl: platformUtils.linkUtils.convertLinkObjectToUrl.bind(platformUtils.linkUtils, routersMap)
      },
      storage: appStorage,
      pubSub: pubSubService,
      mediaItemUtils: platformUtils.mediaItemUtils
    }
  }
}
