'use strict'
const _ = require('lodash')

function getParametersAsString(queryObject) {
  function toParameterNameValue(name) {
    return `${name}=${queryObject[name]}`
  }

  return Object.keys(queryObject).map(toParameterNameValue).join('&')
}

function joinURL() {
  /*eslint-disable santa/no-for-loop */
  let url = arguments[0]
  for (let i = 1; i < arguments.length; ++i) {
    url = `${url.replace(/\/$/, '')}/${arguments[i].replace(/^[\/]*/, '')}`
  }
  return url
}

function parseUrlParams(queryString) {
  const re = /([^&=]+)=?([^&]*)/g
  let param
  const query = {}

  while ((param = re.exec(queryString)) !== null) {
    const key = decodeURIComponent(param[1])
    const val = decodeURIComponent(param[2])
    if (!query[key]) {
      // first value for key, keep as string
      query[key] = val
    } else if (Array.isArray(query[key])) {
      // more than one value already, push to the array
      query[key].push(val)
    } else {
      // the 2nd value for the key, turn into an array
      query[key] = [query[key], val]
    }
  }

  return query
}

function getQueryParamsFromUrl(url) {
  const startQuery = url.indexOf('?')
  if (startQuery === -1) {
    return {}
  }

  let queryString = url.substring(startQuery + 1)
  if (queryString.indexOf('#!') !== -1) {
    queryString = queryString.substring(0, queryString.indexOf('#!'))
  }

  return parseUrlParams(queryString)
}

function addQueryParamToUrl(url, queryParam) {
  if (!url || url === '') {
    return url
  }

  const delimiter = _.includes(url, '?') ? '&' : '?'
  const urlParts = _.split(url, '#')
  urlParts[0] += delimiter + queryParam
  return urlParts.join('#')
}

module.exports = {
  getParametersAsString,
  getQueryParamsFromUrl,
  joinURL,
  addQueryParamToUrl
}
