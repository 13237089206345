'use strict'

const errors = {
  PLATFORM_RENDER_ERROR: {
    params: {
      src: 72,
      evid: 310
    },
    endpoint: 'performance',
    isSampled: false
  }
}

module.exports = errors
