'use strict'

const _ = require('lodash')
const Emitter = require('tiny-emitter')
const constants = {
  intent: {
    WIX_CODE_SITE_API: 'WIX_CODE_SITE_API'
  },
  messageTypes: {
    PUBLISH: 'publishPubSubEvent',
    SUBSCRIBE: 'subscribePubSubEvent',
    UNSUBSCRIBE: 'unsubscribePubSubEvent'
  }
}

function getCallId(pubSub) {
  return ++pubSub.callId
}

function eventKeyBuilder(appDefId, eventKey) {
  return appDefId + eventKey
}

function publish(workerId, appDefId, messageService, eventKey, eventData, isPersistent = false) {
  messageService.sendMessage({
    workerId,
    appDefId,
    intent: constants.intent.WIX_CODE_SITE_API,
    type: constants.messageTypes.PUBLISH,
    msgData: {
      eventKey,
      eventData,
      isPersistent
    }
  })
}

function subscribe(workerId, appDefId, messageService, eventKey, cb, receivePastEvents = false) {
  const msgData = {
    eventKey,
    receivePastEvents
  }

  this.emitter.on(eventKeyBuilder(appDefId, eventKey), cb)

  if (receivePastEvents) {
    const callbackId = getCallId(this)
    this.emitter.on(callbackId, cb)
    msgData.callbackId = callbackId
  }
  messageService.sendMessage({
    appDefId,
    intent: constants.intent.WIX_CODE_SITE_API,
    type: constants.messageTypes.SUBSCRIBE,
    workerId,
    msgData
  })
}

function unsubscribe(workerId, appDefId, messageService, eventKey) {
  this.emitter.off(eventKeyBuilder(appDefId, eventKey))

  messageService.sendMessage({
    appDefId,
    workerId,
    intent: constants.intent.WIX_CODE_SITE_API,
    type: constants.messageTypes.UNSUBSCRIBE,
    msgData: {
      eventKey
    }
  })
}

class PubSubService {
  constructor(messageService) {
    this.messageService = messageService
    this.emitter = new Emitter()
    this.callId = 0
  }

  handlePubSubMessage(message) {
    const messageData = message.data
    if (!messageData) {
      return
    }
    if (messageData.invokePastEvents && _.isNumber(messageData.callbackId) && _.isArray(messageData.eventDataArray)) {
      messageData.eventDataArray.forEach(data => this.emitter.emit(messageData.callbackId, data))
      this.emitter.off(messageData.callbackId)
    } else {
      this.emitter.emit(eventKeyBuilder(message.appDefId, messageData.eventKey), messageData.eventData)
    }
  }

  getBoundedAPI(workerId, appDefId) {
    return {
      publish: publish.bind(this, workerId, appDefId, this.messageService),
      subscribe: subscribe.bind(this, workerId, appDefId, this.messageService),
      unsubscribe: unsubscribe.bind(this, workerId, appDefId, this.messageService)
    }
  }
}

module.exports = PubSubService
