'use strict'

function validateProp(script, prop) {
  return script[prop]
}

function validateScripts(scripts, properties) {
  const errorScripts = scripts.filter(script => !properties.every(validateProp.bind(null, script))).map(script => JSON.stringify(script))

  if (errorScripts.length) {
    throw new Error(`scripts must contain ${properties.join(', ')}: ${errorScripts}`)
  }
}

module.exports = {
  validate: validateScripts
}
