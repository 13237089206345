'use strict'

const platformUtils = require('santa-platform-utils/dist/esm/viewer-platform-worker-api')
const _ = require('lodash')
const constants = require('../../constants/constants')
const wixCodeMessageType = require('../../wixCodeMessageTypes')
const {fedops} = require('../../utils/loggingUtils')
const {measurePerformanceEnd, measurePerformanceStart} = require('../workerUtils')
const {getGhostStructure} = require('../ghostModel')

function createStartHandler({messageService, store}) {
  function createRemoteModelInterface(context, apps, {ghostComps = {}, ghostConnections = {}} = {}) {
    const RMI = new platformUtils.RemoteModelInterface(context)
    apps.forEach(app => {
      if (_.get(app, 'module.exports')) {
        RMI.setAppPublicAPI(app.appDefId, app.module.exports)
      }
      Object.keys(app.controllers).forEach(controllerId => {
        RMI.setPublicAPI(controllerId, app.controllers[controllerId].exports)
      })
    })
    _.forEach(ghostComps, (ghostComp, ghostId) => RMI.addComponent(ghostId, ghostComp, true))
    RMI.addConnections(ghostConnections)
    return RMI
  }

  function initSdk(messageData, RMI, sdk) {
    sdk.__INTERNAL__.initModel({RMI, componentsHooks: platformUtils.componentsHooks}, messageData.id)
    if (_.isFunction(sdk.__INTERNAL__.addAppStudioGlobalsIfNeeded)) {
      sdk.__INTERNAL__.addAppStudioGlobalsIfNeeded(messageData.id)
    }
    self.$w = sdk.getSelector(messageData.id)
    self.ga = sdk.ga
  }

  function exposePublicAPI({module: appModule = {}, appDefId} = {}, workerId) {
    if (self.pmrpc && appModule.exports && !_.isEmpty(appModule.exports)) {
      self.pmrpc.api.set(`${wixCodeMessageType.PLATFORM_PUBLIC_API_PREFIX}${appDefId}_${workerId}`, appModule.exports)
      messageService.sendMessage({
        type: wixCodeMessageType.REQUEST_API,
        intent: wixCodeMessageType.WIX_CODE_INTENT,
        appDefId,
        workerId
      })
    }
  }

  async function handleStart(messageData, {workerId, getAllApps, env, sdk}) {
    measurePerformanceStart('start')
    if (!messageData.id) {
      throw new Error('Could not init sdk: `context.id` is missing')
    }
    if (!messageData.context) {
      throw new Error('Could not init sdk: `context.context` is missing')
    }

    if (!self.pmrpc && self.importScripts) {
      self.importScripts(constants.PM_RPC)
    }

    const allApps = _.isEmpty(messageData.apps) ?
      getAllApps() :
      getAllApps().filter(app => _.includes(messageData.apps, app.appDefId))
    if (env !== 'backend') {
      allApps.forEach(app => exposePublicAPI(app, workerId))
    }
    const allReady = allApps.map(app => {
      app.controllersReady = app.controllersReady && app.controllersReady.then(() => 
         Promise.resolve() //eslint-disable-line promise/no-return-wrap
      )
      return Promise.resolve(app.controllersReady)
    })

    await Promise.all(allReady)
    const ghostModel = await getGhostStructure(messageData.context, store.getValue('appStudioWidgetsStructureUrl'))
    const RMI = createRemoteModelInterface(messageData.context, getAllApps(), ghostModel)
    initSdk(messageData, RMI, sdk)
    sdk.__INTERNAL__.triggerOnReady(() => {
      messageService.sendWidgetReadyMessage(messageData.id)
      fedops.reportPlatformLoaded()
      if (_.get(self, 'performance.getEntriesByType')) {
        measurePerformanceEnd('start')
        const performanceMetrics = self.performance.getEntriesByType('measure')
        messageService.sendPerformanceMetricsMessage(workerId, JSON.stringify(performanceMetrics))
      }
    })
  }

  return handleStart
}

module.exports = createStartHandler
