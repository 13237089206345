'use strict'

const workerUtils = require('./workerUtils')
const scriptsValidator = require('./scriptsValidator')
const {fedops, ACTION_NAMES} = require('../utils/loggingUtils')
const _ = require('lodash')


function loadUserGeneratedApps({applications = []} = {}, appsStore) {
  const {reportInteractionStarted, reportInteractionEnded} = fedops.getInteractionReportFunctions({name: ACTION_NAMES.USER_GENERATED_APPS_LOADED})
  const beforeLoad = Date.now()
  reportInteractionStarted()
  scriptsValidator.validate(applications, ['id', 'url'])
  const applicationsToLoad = applications.filter(app => !appsStore.get(app.id))
  workerUtils.importModules(applicationsToLoad, appsStore)
  reportInteractionEnded({duration: _.now() - beforeLoad})
}

module.exports = {
  loadUserGeneratedApps
}
