'use strict'
const getBiSessionDataPath = innerRoute => `biSessionData.${innerRoute}`

module.exports = {
  BI_LOGGER_FACTORY: 'biLoggerFactory',
  BI_LOGGERS: 'biLoggers',
  TRACE_LOGGERS: 'traceLoggers',
  SAMPLED_BI_LOGGERS: 'sampledBiLoggers',
  FEDOPS_LOGGERS: 'fedOpsLoggers',
  WIXCODE_BI_LOGGERS: 'wixCodeBiLoggers',
  IS_DEBUG: 'isDebug',
  COIN: getBiSessionDataPath('coin'),
  IS_SERVER_SIDE: getBiSessionDataPath('isServerSide'),
  MUTE_BI: getBiSessionDataPath('muteBi'),
  IS_PREVIEW: 'isPreview',
  BI_SAMPLE_BY_REQUEST_ID: 'biSampleByRequestId',
  REPORT_TRACE: 'reportTrace',
  FEDOPS_NO_SAMPLING: 'fedopsNoSampling',
  REPORT_PLATFORM_FEDOPS: 'reportPlatformFedops',
  BI_SESSION_DATA: 'biSessionData',
  PAGE_LOAD_START: getBiSessionDataPath('pageLoadStart'),
  SSR_REQUEST_TIMESTAMP: getBiSessionDataPath('ssrRequestTimestamp'),
  NETWORK_PAGE_LOAD_START: getBiSessionDataPath('networkPageLoadStart'),
  PAGE_ID: getBiSessionDataPath('pageId'),
  PAGE_NUMBER: getBiSessionDataPath('pageNumber'),
  PAGE_URL: getBiSessionDataPath('pageUrl')
}
