'use strict'

const scriptMap = {}
const {addQueryParamToUrl} = require('../utils/urlUtils')
const {bi, fedops, ACTION_NAMES} = require('../utils/loggingUtils')
const {PREFETCH_SCRIPT_EVAL_FAILED, IMPORT_SCRIPT_WITHOUT_CACHEKILLER_FAILED} = ACTION_NAMES
const _ = require('lodash')

function sendBi(error, errorType, url, beforeLoad) {
  return bi.reportPlatformRenderError({
    name: errorType,
    duration: _.now() - beforeLoad,
    details: JSON.stringify({scriptUrl: url}),
    error: error.message
  })
}

function handleScript({url, script}) {
  if (!scriptMap[url]) {
    scriptMap[url] = script
  }
}

function bytesToString(ab) {
  if (typeof TextDecoder !== 'undefined') {
    try {
      const td = new TextDecoder()
      return td.decode(ab)
    } catch (e) {
      // empty
    }
  }
  try {
    //eslint-disable-next-line no-undef
    return String.fromCharCode.apply(null, new Uint8Array(ab))
  } catch (e) {
    // empty
  }
  return null
}

function cacheKiller() {
  return Date.now()
}

function importScripts(url, appId, scriptName, beforeLoad) {
  const fedopsParams = appId ? {appId} : {}
  const script = scriptMap[url]
  if (script) {
    delete scriptMap[url]
    const code = bytesToString(script)
    if (code) {
      try {
        //eslint-disable-next-line no-eval
        const {reportInteractionEnded, reportInteractionStarted} = fedops.getInteractionReportFunctions({name: ACTION_NAMES.SCRIPT_EVAL, details: scriptName, params: fedopsParams})
        reportInteractionStarted()
        eval.call(null, `${code}\n//# sourceURL=${url}`) // eslint-disable-line no-eval
        reportInteractionEnded({duration: _.now() - beforeLoad})
        return
      } catch (error) {
        sendBi(error, PREFETCH_SCRIPT_EVAL_FAILED, url, beforeLoad)
      }
    }
  }

  try {
    const {reportInteractionEnded, reportInteractionStarted} = fedops.getInteractionReportFunctions({name: ACTION_NAMES.SCRIPT_LOADED, details: scriptName, params: fedopsParams})
    reportInteractionStarted()
    self.importScripts(url)
    reportInteractionEnded({duration: _.now() - beforeLoad})
  } catch (err) {
    sendBi(err, IMPORT_SCRIPT_WITHOUT_CACHEKILLER_FAILED, url, beforeLoad)
    const urlWithCacheKiller = addQueryParamToUrl(url, `cache-killer=${cacheKiller()}`)
    const {reportInteractionEnded, reportInteractionStarted} = fedops.getInteractionReportFunctions({name: ACTION_NAMES.SCRIPT_LOAD_RETRY, details: scriptName, params: fedopsParams})
    reportInteractionStarted()
    self.importScripts(urlWithCacheKiller)
    reportInteractionEnded({duration: _.now() - beforeLoad})
  }
}

module.exports = {
  handleScript,
  importScripts
}
