'use strict'
const _ = require('lodash')
const {PublishMethods, factory} = require('@wix/web-bi-logger/dist/src/logger')
const {create} = require('@wix/fedops-logger')

const MESSAGE_TYPE = 'reportBI'
const MESSAGE_INTENT = 'WIX_CODE_SSR'

const serverBiLoggerFactory = (biStore, muteFunc, postMessageFunc) => {
    const factoryInstance = factory({
        publishMethod: PublishMethods.PostMessage,
        useBatch: false
    })
        .setMuted(muteFunc())
        .withUoUContext({
            msid: () => biStore.metaSiteId
        })
        .updateDefaults({
            ts: () => biStore.getLoadingTime(),
            tsn: () => biStore.getTotalLoadingTime(),
            pn: 1,
            pageId: () => biStore.pageId,
            pageUrl: () => biStore.pageUrl,
            _av: biStore.artifactVersion,
            isServerSide: true,
            is_lightbox: biStore.isPopup
        })
        .withTransformer({
            [PublishMethods.PostMessage]: params => ({
                type: MESSAGE_TYPE,
                intent: MESSAGE_INTENT,
                params
            })
        })

    if (_.isFunction(postMessageFunc)) {
        factoryInstance.withPublishFunction({
            [PublishMethods.PostMessage]: postMessageFunc
        })
    }

    return factoryInstance
}

const clientBiLoggerFactory = (biStore, muteFunc, useBatch) =>
    factory({publishMethod: PublishMethods.Auto, useBatch})
        .setMuted(muteFunc())
        .withUoUContext({
            msid: () => biStore.metaSiteId,
            visitorId: biStore.visitorId,
            siteMemberId: () => biStore.siteMemberId
        })
        .updateDefaults({
            ts: () => biStore.getLoadingTime(),
            tsn: () => biStore.getTotalLoadingTime(),
            pn: () => biStore.pageNumber,
            pageId: () => biStore.pageId,
            pageUrl: () => biStore.pageUrl,
            rid: biStore.requestId,
            _av: biStore.artifactVersion,
            isServerSide: false,
            is_lightbox: biStore.isPopup
        })

const getServerFedOpsLogger = (biStore, muteFunc, postMessageFunc) => create('platform-logger', {
    isServerSide: true,
    biLoggerFactory: serverBiLoggerFactory(biStore, muteFunc, postMessageFunc),
    phasesConfig: 'SEND_ON_APP_LOADED',
    isPersistent: true
})

const getClientFedOpsLogger = (biStore, muteFunc, useBatch) => create('platform-logger', {
    isServerSide: false,
    biLoggerFactory: clientBiLoggerFactory(biStore, muteFunc, useBatch),
    phasesConfig: 'SEND_ON_APP_LOADED',
    isPersistent: true
})

const getFedOpsLoggers = (biStore, muteFunc, postMessageFunc, useBatch) => ({
    server: getServerFedOpsLogger(biStore, muteFunc, postMessageFunc),
    client: getClientFedOpsLogger(biStore, muteFunc, useBatch)
})

const getBiLoggers = (biStore, muteFunc, postMessageFunc, useBatch, endpoint) => ({
    server: serverBiLoggerFactory(biStore, muteFunc, postMessageFunc).logger({endpoint}),
    client: clientBiLoggerFactory(biStore, muteFunc, useBatch).logger({endpoint})
})

const biLoggerFactoryForApp = (biStore, muteFunc, postMessageFunc, useBatch) => appDefaults => () => {
    const mappedAppDefaults = _.reduce(appDefaults, (res, value, key) => _.set(res, `_${key}`, value), {})
    const defaults = Object.assign({}, {
        rid: biStore.requestId,
        _av: biStore.artifactVersion,
        _siteOwnerId: biStore.ownerId,
        _viewMode: biStore.isPreview ? 'preview' : 'site'
    }, mappedAppDefaults)

    const factoryInstance = factory({useBatch})
        .setMuted(muteFunc())
        .withUoUContext({
            msid: () => biStore.metaSiteId,
            visitorId: biStore.visitorId,
            siteMemberId: () => biStore.siteMemberId
        })
        .updateDefaults(defaults)

    if (_.isFunction(postMessageFunc)) {
        factoryInstance.withPublishFunction({
            [PublishMethods.PostMessage]: postMessageFunc
        })
    }
    return factoryInstance
}

module.exports = {
    getFedOpsLoggers,
    getBiLoggers,
    biLoggerFactoryForApp
}
